import { default as Vuex, Module } from "vuex";
import router from "@/router";
import store from "../index";
import * as Models from "@gigalot/data-models";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { initRtc } from "@/main";
import * as RtcClient from "@gigalot/rtc-client";
import { sendFCMToken } from "../../main";

let hasfirebaseOnAuthStateChangedFired: boolean = false;
let callbacks: (() => void)[] = [];

function addCallback(callback: () => void) {
  if (hasfirebaseOnAuthStateChangedFired) callback();
  else callbacks.push(callback);
}

function fireCallbacks() {
  hasfirebaseOnAuthStateChangedFired = true;
  let callback: (() => void) | undefined;
  while ((callback = callbacks.shift())) callback();
}

class UserState {
  /*
  some of the fields of firebase.User (user):

  displayName: string | null;
  email: string | null;
  phoneNumber: string | null;
  photoURL: string | null;
  providerId: string;
  uid: string;
  */
  //user?: firebase.User;
  user?: any;
  authorizationLevel?: number;
  gcp?: string;
  location?: { guid: string; description: string; gcp: string }; //new location, guid of location set in admin app
  accessToken?: string;
  locations: { guid: string; description: string; gcp: string }[] = [];
}

class UserModule implements Module<UserState, any> {
  namespaced = true;
  state: UserState = new UserState();
  mutations = {
    setAuthorizationLevel(state: UserState, payload: number) {
      state.authorizationLevel = payload;
    },
    setUser(state: UserState, payload: any) {
      state.user = payload;
      if (!payload) {
        state.authorizationLevel = undefined;
        state.gcp = undefined;
        state.location = undefined;
      }
    },
    setUserInfo(state: UserState, payload: { authorizationLevel: number; gcp: string; location: string }) {
      state.authorizationLevel = payload.authorizationLevel;
      state.gcp = payload.gcp;
      state.location = {guid: payload.location, description: payload.gcp, gcp: payload.gcp};
    },
    updateAccessToken(state: UserState, payload: { jwt: string }) {
      state.accessToken = payload.jwt;
    },
    locations(state: UserState, locations: { guid: string; description: string; gcp: string }[]) {
      state.locations = locations;
    },
    location(state: UserState, location: { guid: string; description: string; gcp: string }) {
      RtcClient.disconnect();
      state.location = location;
      initRtc();
      sendFCMToken();
    }
  };
  actions = {
    async getOfflineIdToken({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }) {
      return state.accessToken;
    },
    async getOnlineIdToken({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }) {
      const auth = getAuth();
      let user = auth.currentUser;
      let jwt;
      if (!user) {
        throw Error("No user");
      }
      jwt = await user.getIdToken();
      if (!jwt) {
        throw Error("No jwt");
      }
      commit("updateAccessToken", { jwt: jwt });
      return jwt;
    },
    async signInWithEmailPassword({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any, dispatch:any }, obj: { email: string; password: string }) {
      const auth = getAuth();
      let result = await signInWithEmailAndPassword(auth, obj.email, obj.password); //try catch happening outside method
      let user = result.user;
      if (!user) {
        throw Error("signInWithEmailAndPassword no result.user");
      }
      console.log(JSON.stringify(user));

      let token;
      try {
      let jwt = await user.getIdToken();
      token = await user.getIdTokenResult();
      let gql = `query userFeederSupervisorLocations($uid: String!) {
        userFeederSupervisorLocations(uid: $uid) {
          guid
          description
          gcp
        }
      }`;

      
      try {
        let json = await dispatch("graphQl", { gql, variables: { uid: user.uid }, destination: "cloud" }, { root: true });

        let locations = json.data.userFeederSupervisorLocations;

        if (locations.length === 0) throw Error("User is not authorized to use this app.");
        else {
          commit("locations", locations);
        }
      } catch (err) {
        throw err;
      }

      //Use uid to query if user has any feeder_supervisor roles
      //If no locations found then deny access
      //If 1 location found then set that location
      //If more than location then let user choose which location to log into

      commit("setUser", JSON.parse(JSON.stringify(user)));
      commit("updateAccessToken", { jwt: jwt });

      router.push({ name: "location" });
      } catch (error) {
        throw Error("Error getting user token." + error);
      }

      if (!token) {
        throw Error("Id token not found.");
      }
      if (!token.claims) {
        throw Error("custom claims not found.");
      }

      // if (token.claims.authorizationLevel !== undefined) {
      //   commit("setAuthorizationLevel", parseInt(token.claims.authorizationLevel));
      //   throw Error("Autorization level not found.");
      // }
      if (!token.claims.gcp) {
        throw Error("GCP not found.");
      }
      if (!token.claims.location) {
        throw Error("Location not found.");
      }
    },
    signOut({ state, commit, rootState }: { state: UserState; commit: any; rootState: any }) {
      const auth = getAuth();
      auth
        .signOut()
        .then(function() {
          RtcClient.disconnect();
          commit("setUser", undefined);
          router.push({ path: "/login" });
          console.log("user signed out");
        })
        .catch(function() {
          console.log("error signing out");
        });
    },
    firebaseOnAuthStateChanged({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any }, user: any) {
      console.log("user vuex sub module firebaseOnAuthStateChanged");
      //commit("setUser", user);

      /*
        Remember that this method gets called shortly after every reload.
        If there is a user then do nothing since they're already logged in.
        If there is no user then log out. This shouldn't really be needed, but it's here for just in case.
      */
      if (!user) {
        console.log("no user");
        commit("setUser", undefined);
        commit("updateAccessToken", { jwt: undefined });
        if (router.currentRoute.path !== "/login") router.push({ path: "/login" });
      }

      fireCallbacks();
    },
    addFirebaseCallback({ state, commit, rootState, dispatch }: { state: UserState; commit: any; rootState: any; dispatch: any }, callback: () => void) {
      addCallback(callback);
    }
  };
  getters = {
    isUserRegistered(state: UserState, getters: any, rootState: any, rootGetters: any) {
      /*
        Returning a function, because for some reason just returning the boolean didn't work.
        calling `if (store.getters["login/isUserRegistered"])` still gets called if isUserRegistered is spelled wrong.
        rather use `if (store.getters["login/isUserRegistered"]())` because then if isUserRegistered is spelled wrong we'll get an error saying so.
      */
      return () => {
        let isUserRegistered: boolean = state.user ? true : false;
        console.log("isUserRegistered: " + isUserRegistered);
        return isUserRegistered;
      };
    },

    isUserGigalotAdmin(state: UserState, getters: any, rootState: any, rootGetters: any) {
      return () => {
      if (state.user?.email.endsWith("gigalot.co.za")) return true;
      else return false;
      };
    },

    getUpstreamMetadata(state: UserState, getters: any, rootState: any, rootGetters: any) {
      return () => {
        if (!state.user) throw Error("getUpstreamMetadata, no user");

        let userName: string = state.user.displayName;
        let userId: string = state.user.uid;
        let userEmail: string = state.user.email;
        let feedlot: string = state.location ? state.location.description : "";
        let gcp: string = state.location ? state.location.gcp : "";
        let location: string = state.location ? state.location.guid : "";

        let metadata: Models.UpstreamMetadata = new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
        return metadata;
      };
    }
  };
}

const userModule: UserModule = new UserModule();

export default userModule;
