
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";
import FeedDeliverySummary from "@/views/FeedDeliverySummary.vue";
import BushDroppings from "@/views/BushDroppings.vue";
import MixResults from "@/views/MixResults.vue";
import * as Model from "@gigalot/data-models";
import lodash from "lodash";
const Subscriber = require("@jetblack/graphql-reconnect-client");
import { createClient } from "@/helpers/graphql-ws-rtc-adapter";
import { EventBus } from '../eventBus';

@Component({
  components: {
    DimssaButton,
    FeedDeliverySummary,
    BushDroppings,
    MixResults
  }
})
export default class FeedDelivery extends Vue {
  name = "feedDelivery";
  search = "";
  feedDeliveries = [];
  selected = [];
  expanded = [];
  loading = false;
  singleExpand = false;
  headers = [
    {
      text: "Time",
      value: "timestamp",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Tractor",
      value: "tractorID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: false,
      group: false
    },
    {
      text: "Trailer",
      value: "trailerID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Operator",
      value: "operatorID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Ration",
      value: "rationFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Kraal",
      value: "kraalID",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    },
    {
      text: "Amount (kg)",
      value: "amountFed",
      align: "center",
      sortable: true,
      filterable: true,
      divider: true
    }
  ];

  urlws = "wss://pi.gigalot.systems:7778/api/ws";
  options = {};

  subscriptions = `subscription($guid: String!) {
     feedResults(guid:$guid){
          timestamp
          latitude
          longitude
          amountFed
          kraalID
          operatorID
          tractorID
          trailerID
          rationFed
          isBushDrop
        }
    }`;

  variables = {guid: this.$store.state.user.location.guid };
  operationName = null;

  mounted() {
    EventBus.$once('component-c-done', this.getFeedDeliveries);
    EventBus.$once('component-c-done', this.getFeedDeliveriesSocket);
    // this.$store.dispatch("user/addFirebaseCallback", this.getFeedDeliveries);
    // this.$store.dispatch("user/addFirebaseCallback", this.getFeedDeliveriesSocket);
  }
  get moment(){
    return this.$store.state.moment;
  }
  destroyed() {
    if (this.graphQLUnsubscribe) {
      this.graphQLUnsubscribe();
    }
    if (this.graphQLSubscriber) {
      this.graphQLSubscriber();
    }
  }

  getKraalName(guid: string) {
    let kraals = this.$store.getters[`storage`]().Kraals as Model.Kraal[];
    let kraal = lodash.find(kraals, { guid: guid });
    if (kraal) return kraal.kraalId;
  }

  getTrailerName(guid: string) {
    let trailers = this.$store.getters[`storage`]().Trailers as Model.Trailer[];
    let trailer = lodash.find(trailers, { electronicID: guid });
    if (trailer) return trailer.wagonRegistration;
  }

  getTractorName(guid: string) {
    let tractors = this.$store.getters[`storage`]().Tractors as Model.Tractor[];
    let tractor = lodash.find(tractors, { electronicID: guid });
    if (tractor) return tractor.registrationNumber;
  }

  getOperatorName(guid: string) {
    let operators = this.$store.getters[`storage`]().Operators as Model.Operator[];
    let operator = lodash.find(operators, { electronicID: guid });
    if (operator) return operator.operatorFullName;
  }

  async getFeedDeliveries() {
    this.loading = true;
    try {
      console.log("getFeedDeliveries()");

      let gql = `query($guid:String!){
        FeedResults(guid:$guid){
          timestamp
          latitude
          longitude
          amountFed
          kraalID
          operatorID
          tractorID
          trailerID
          rationFed
          isBushDrop
        }
      }`;
      let json = await this.$store.dispatch("graphQl", { gql, variables:{guid:this.$store.state.user.location.guid }, url: "https://pi.gigalot.systems:7777/feeder" });
      console.log(`Done Downloading Feed Results: ${json.data.FeedResults.length}`);
      this.feedDeliveries = json.data.FeedResults;
      this.loading = false;
    } catch (err) {
      console.log("getFeedDeliveries -> error: " + err);
    }
    
  }

  graphQLSubscriber: any = undefined;
  graphQLUnsubscribe: any = undefined;
  get themeClass(): string {
    return this.$store.state.lightDarkMode;
  }

async getFeedDeliveriesSocket(){
 if (this.$store.state.useP2PConn){
      await this.getOnlineFeedDeliveriesSocket();
    }
    else{
      await this.getOnsiteFeedDeliveriesSocket();
    }
  }
 

  async getOnlineFeedDeliveriesSocket() {


  let variables = { guid: this.$store.state.user.location.guid, user: this.$store.state.user.user.uid };

    const subscribe = (subscriptions: string, variables: any, callback: (error: any, data: any) => Promise<void>) => {
      const client = createClient();
      let unsubscribe = client?.subscribe(
        {
          query: subscriptions,
          variables: variables,
        },
        {
          next: (message: any) => callback(undefined, message.data),
          error: (err: any) => {
            throw err;
          },
          complete: () => console.log("subscription completed"),
        }
      );
      return unsubscribe;
    };

    this.graphQLUnsubscribe = subscribe(this.subscriptions, variables, async (error: any, data: any) => {
      this.$store.state.wsConnected = true;
      if (!(error || subscribe)) {
        console.log("subscribe success");
        this.$store.state.wsDialogVisible = false;
        // Normal closure
        return;
      }
      if (error) {
        console.log("error subscribing");

        this.$store.state.wsConnected = false;
        if (error.event.type === "close") {
        } else {
          this.$store.state.wsDialogVisible = true;
          console.error(error);
          //    throw error;
        }
      }
      this.$store.state.wsDialogVisible = false;
      this.$store.state.wsConnected = true;
      console.log("almost there");
      this.feedDeliveries = data.feedResults;
    });
  }
  
  async getOnsiteFeedDeliveriesSocket() {
    this.graphQLSubscriber = Subscriber.graphQLReconnectingSubscriber(
      this.urlws,
      this.options,
      (error: any, subscribe: any) => {
        try {
          if (!(error || subscribe)) {
            console.log("connected to socket");
            this.$store.state.wsDialogVisible = false;
            return;
          }
          if (error) {
            console.log("error connecting to socket");
            if (error.event.type === "close") {
            } else {
              this.$store.state.wsDialogVisible = true;
              console.error(error);
            }
          }
          console.log("socket connected");
          this.graphQLUnsubscribe = subscribe(this.subscriptions, this.variables, this.operationName, (error: any, data: any) => {
            if (!(error || subscribe)) {
              console.log("subscribe success");
              this.$store.state.wsDialogVisible = false;
              return;
            }
            if (error) {
              console.log("error subscribing");
              if (error.event.type === "close") {
              } else {
                this.$store.state.wsDialogVisible = true;
                console.error(error);
              }
            }
            this.$store.state.wsDialogVisible = false;
            console.log("receiving data");
            this.feedDeliveries = data.feedResults;
            this.loading = false;
          });
        } catch (err) {
        }
      },
      15000,
      3,
      "graphql-ws"
    );
  }

}
