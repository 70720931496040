import lodash from "lodash";
import { ActionContext } from "vuex";
import { UploadState } from "@/store/modules/upload";
import { UploadableModel, UploadableScanningResult } from "@/models/uploadable";
import store from "@/store/index";

async function uploadData(data: any, url: string, jwt: string, context: ActionContext<UploadState, any>): Promise<Response> {
  let options: RequestInit = {
    method: "POST",
    mode: "cors",
    cache: "no-store",
    credentials: process.env.NODE_ENV === "production" ? "include" : undefined, //TODO: should we also disable credentials when using http?
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: "Bearer " + jwt
    }),
    body: JSON.stringify(data)
  };

  let response = await fetch(url, options);

  return response;
}

async function setUploaded(guid: string, getAction: string, setAction: string, context: ActionContext<UploadState, any>) {
  let savedData = await context.dispatch(getAction, guid, { root: true });
  savedData.uploaded = true;
  await context.dispatch(setAction, savedData, { root: true });
}

async function uploadModelGql(
  model: UploadableModel,
  variableName: string,
  getAction: string,
  setAction: string,
  destination: string,
  resolver: string | undefined,
  context: ActionContext<UploadState, any>,
  gql: string
) {
  let guid = model.guid;

  let toUpload = lodash.cloneDeep(model);
  delete toUpload.uploaded;
  const locationGuid: any = context.rootState.user.location?.guid;
  if (!locationGuid) throw Error("uploadModelGql error: No location guid found!");
  let variables = { [variableName]: toUpload, guid: locationGuid };
  try {
    console.log("Attempting to upload model with gql.");
    let jwt = await context.dispatch("user/getOfflineIdToken", undefined, { root: true });
    let response = await context.dispatch("graphQl", { gql, variables, jwt, destination, resolver }, { root: true });
    console.log(response);
    if (response.errors){
      store.commit("popup/displayOk",  `Error: ${response.errors[0].message}`);
    }
    await setUploaded(guid, getAction, setAction, context);

  } catch (err) {
    console.log("Error uploading model with gql.");
    console.log(err);

  }

}

export async function uploadCribScore(dispatchModel: UploadableModel, context: ActionContext<UploadState, any>) {
  let getAction: string = "data/getCribScore";
  let setAction: string = "data/addCribScore";
  let destination = "feeder-server";

  let gql = `mutation AddCribReading($guid:String!, $input: CribReadingItemInput!) {
    addCribReading(guid: $guid, input: $input)
  }`;

  await uploadModelGql(dispatchModel, "input", getAction, setAction, destination, undefined, context, gql);
}
