
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
const vuexMapFields = require("vuex-map-fields");
import {required, minLength, alphaNum } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
const mapFields = vuexMapFields.mapFields;
import store from "@/store";


function duplicated(value:string){
  const cm = store.getters["storage"]().line
  const lines = store.getters["storage"]().Lines.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(lines,{code:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : {code: {required, duplicated},
    displayName: {required}},
  computed: {
    ...mapFields(["line.code", "line.displayName", "line.active", "line.kraals"])
  }
})
export default class Lines extends Vue {

   get line(): Models.Line {
    return this.$store.getters["storage"]().line;
  }

  cancel() {
    this.$router.go(-1);
  }

  created() {
    let wtf = this.line
    let x = this.line.kraals;
    console.log("123")
  }

  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().line);
          let lineClone = lodash.cloneDeep(this.$store.getters["storage"]().line);
          lineClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();//new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation line($guid: String!, $line: LineInput!) {
                line(guid: $guid, line: $line)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                line: lineClone
              }
            },
            { root: true }
          );
          

          } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
    await this.lines();
      }
    });
  }

  mounted() {}

  destroyed() {}

  editComponentIndex: number | null = null;

  editComponent(index: number) {
    this.editComponentIndex = index;
  }

  confirmEditComponent(kraal: Models.Kraal, index: number) {
    kraal.lineGuid = this.line.guid;
    this.line.kraals[index] = kraal;
    this.$store.commit("store", { line: this.line });
    this.editComponentIndex = null;
  }
  
  addComponent() {
    let kraal: Models.Kraal = new Models.Kraal();
    this.line.kraals.push(kraal);
    let x = this.line.kraals;
    this.$store.commit("store", { line: this.line });
    this.editComponentIndex = this.line.kraals.length - 1;
  }

async lines(push: boolean = true) {
    try {
    
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.lines,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
     
      this.$store.commit("updateField", {
        path: "Lines",
        value: json.data.Lines
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/lines" });
  }

}
