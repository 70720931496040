import Vue from "vue";
import App from "./App.vue";
import { registerServiceWorker, _onRegistrationDispatcher, swr } from "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/analytics";

import { EventHandler } from "./event";
import * as Model from "@gigalot/data-models";
import { v4 } from "uuid";
import browserDetect from "vue-browser-detect-plugin";
import Vuelidate from 'vuelidate';
import mitt from "mitt";
import * as RtcClient from "@gigalot/rtc-client";
import {identify} from "./store/modules/logger"
import { getToken } from "firebase/messaging";
import { getAuth } from "firebase/auth";
const VueGoogleMaps = require("vue2-google-maps");
Vue.use(browserDetect);

store.commit("version");
let fcmToken: string | undefined = undefined;

//Firebase --------------------------------------------------------------------------------------------------------------------------

let firebaseConfig: any;
if (store.state.environment === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyAnm2wf7gkEjHhNi59Fv2-U8-isAZhD6UA",
    authDomain: "gigalot-cloud.firebaseapp.com",
    databaseURL: "https://gigalot-cloud.firebaseio.com",
    projectId: "gigalot-cloud",
    storageBucket: "gigalot-cloud.appspot.com",
    messagingSenderId: "820134020112",
    appId: "1:820134020112:web:813f7a3b58146e4a0e3695",
    measurementId: "G-SRQ543TS3D"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    authDomain: "gigalot-testing.firebaseapp.com",
    databaseURL: "https://gigalot-testing.firebaseio.com",
    projectId: "gigalot-testing",
    storageBucket: "gigalot-testing.appspot.com",
    messagingSenderId: "17723559284",
    appId: "1:17723559284:web:a7adb8bac6fbc0cf137021",
    measurementId: "G-S1MMT9BBJL"
  };
}
//firebase.initializeApp();
export const app = initializeApp(firebaseConfig);
//TODO : Fix
// firebase.analytics();

import { getMessaging, onMessage } from "firebase/messaging";


let handle: EventHandler<ServiceWorkerRegistration> = (registration: ServiceWorkerRegistration) => {

  //Firebase  Messaging --------------------------------------------------------------------------------------------------------------------------

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      
      if (store.state.environment === "production") {
        fcmToken = await getToken(messaging, { vapidKey: "BJ51NwK8y-sC637TRMnzjKqvO6pVdzH5KfHzZPqVbyIY2yJ2QwiPSkAnOOYifPRQlZEkmKXUPXl0HGBjsOfwY0U", serviceWorkerRegistration: registration });
      }
      else {
        fcmToken = await getToken(messaging, { vapidKey: "BP4j18DOhY9tg98HQPQ6ljD217jBIwToogH-tYocE3SiigVcq83Qns22U-e2FCFdl82gOFH20mbZrqDwXFYsU_I", serviceWorkerRegistration: registration });
        
      }
      console.log("FCM Token:", fcmToken);
    } else {
      console.log("Notification permission denied.");
    }
  }
  requestPermission();
  
  }; //end service worker


  const messaging = getMessaging();
onMessage(messaging, (payload) => {
  console.log('Message received. foreground ', payload);
  // ...
});


_onRegistrationDispatcher.register(handle);
registerServiceWorker();

Vue.config.productionTip = false;

if ((window.origin.startsWith("https://feeder.gigalot.co.za")) || (window.origin.startsWith("https://feed.gigalot.co.za")) || (window.origin.startsWith("https://gigalot-cloud-feeder-app-2--preview"))) {
  console.log("Production environment detected.");
  store.commit("environment", "production");
} else if ((window.origin.startsWith("https://feeder-dot-gigalot-testing.appspot.com")) ||(window.origin.startsWith("https://feeder.gigalot.systems")) || (window.origin.startsWith("https://feed.gigalot.systems"))) {
  console.log("Staging (cloud) environment detected.");
  store.commit("environment", "staging");
} else {
  console.log("Testing (local) environment detected.");
  store.commit("environment", "testing");
}


async function sendTokenToServer(browserToken: string) {
console.log(browserToken);
if (browserToken) {
  try {
    let upstreamMetadata = store.getters["user/getUpstreamMetadata"]();

    let browser: Model.Browser = {
      guid: v4(),
      pushToken: browserToken,
      agent: vm.$browserDetect.meta.name, 
      userId: upstreamMetadata.userId,
      metadata: upstreamMetadata,
      typename: "Browser"
    };
    console.log("updateServer() " +  upstreamMetadata.location);
    let json = await store.dispatch(
      "graphQl",
      {
        gql: `mutation addBrowserToken($guid: String!, $browser: BrowserInput!) {
          addBrowserToken(guid: $guid, browser: $browser) 
        }`,
        variables: { guid: upstreamMetadata.location, browser: browser }
      },
      { root: true }
    );
   
  } catch (err) {
    console.log(err);
    throw err;
  }
}
}

// getAuth().onAuthStateChanged(async function(user) {
//   if (user != null){
//   await store.dispatch("user/firebaseOnAuthStateChanged", user);
//   identify(user);
//   if (fcmToken) {
//     sendTokenToServer(fcmToken);
//   }
// }
// });

//RTC --------------------------------------------------------------------------------------------------------------------------
type RtcEvents = {
  connectionEvent: "connected-cloud" | "connected-local" | "disconnected" | "connecting";
};
export const rtcEmitter = mitt<RtcEvents>();

export function initRtc() {
  try {
    rtcInitialized = false;
    if (!(store.state as any)?.user?.location?.guid) {
      console.error("Can not init WebRTC, no locationGuid found");
      return;
    }
    store.commit("rtcSignalling", "connecting");
    RtcClient.initialize({
      locationGuid: (store.state as any).user.location.guid,
      firebaseConfig: firebaseConfig,
      server: "feeder",
      debug: {
        messaging: {
          sending: false,
          receiving: false
        },
        signalling: false,
        iceCandidates: true,
        offers: false,
        answers: false
      },
      port: 7779,
      reconnect: {
        retryTime: 5000,
        maxRetryTime: 30 * 1000
      },

      onConnectionChange: (connected, signalling, state) => {
        console.log("onConnectionChange");
        console.log("connected", connected);
        console.log("signalling", signalling);

        if (state === "connecting") {
          store.commit("rtcSignalling", "connecting");
        } else if (state === "connected-local") {
          store.commit("rtcSignalling", "local");
          fireRtcReadyCallbacks();
          rtcEmitter.emit("connectionEvent", "connected-local");
        } else if (state === "connected-cloud") {
          store.commit("rtcSignalling", "cloud");
          fireRtcReadyCallbacks();
          rtcEmitter.emit("connectionEvent", "connected-cloud");
        } else if (state === "disconnected") {
          store.commit("rtcSignalling", "disconnected");
          rtcEmitter.emit("connectionEvent", "disconnected");
        }
      },
      getJwt: () => store.dispatch("user/getOnlineIdToken", undefined, { root: true }),
      project: store.state.environment === "production" ? "gigalot-cloud" : "gigalot-testing",
      
    });
  } catch (err) {
    console.error(err);
  }
}

const rtcReadyCallbacks: any[] = [];
let rtcInitialized: boolean = false;

export function addRtcReadyCallback(callback: () => void) {
  if (rtcInitialized) callback();
  else rtcReadyCallbacks.push(callback);
}

function fireRtcReadyCallbacks() {
  rtcInitialized = true;
  let callback: () => void;
  while ((callback = rtcReadyCallbacks.shift())) callback();
}

store.commit("rtcSignalling", "disconnected");

getAuth().onAuthStateChanged(function (user: any) {
  store.dispatch("user/firebaseOnAuthStateChanged", user);
});

export function sendFCMToken(){
  identify((store.state as any).user.user);
  initRtc();
  if ((store.state as any).user.user != null){
    if (fcmToken) {
      sendTokenToServer(fcmToken);
    }
  }
}

// store.dispatch("user/addFirebaseCallback", async () => {
//   let upstreamMetadata = store.getters["user/getUpstreamMetadata"]();
//   await store.dispatch("create", {firebaseConfig: firebaseConfig, locationGuid: upstreamMetadata.location,configuration:configuration});
// });

//Google Maps --------------------------------------------------------------------------------------------------------------------------
//TODO: consider moving this to the main app
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCxvvbSahUACHC8z76pXKVQex9XCGB5iY4",
    mapId: "4796866cc28f5c9b"
    //libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  }
});

var vm = new Vue({ router,  store,  vuetify,  render: h => h(App) }).$mount("#app");
Vue.use(Vuelidate);

