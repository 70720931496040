
import { Component, Vue } from "vue-property-decorator";
import { DimssaButton, ButtonState } from "@/components/shared/dimssa-button.vue";

@Component({
  components: {
    DimssaButton,
    Keypress: () => import("vue-keypress"),
  },
})
export default class Login extends Vue {
  email: string = "";
  password: string = "";
  errorMessage: string = "";

  signInButtonState: ButtonState = "ready";

  passwordUrl() {
    if (this.$store.state.environment === "production") {
      return "https://password.gigalot.co.za";
    } else return "https://password.gigalot.systems";
  }

  async login() {
    try {
      this.errorMessage = "";
      this.signInButtonState = "busy";
      await this.$store.dispatch("user/signInWithEmailPassword", {
        email: this.email.trim(),
        password: this.password,
      });
      this.signInButtonState = "ready";
    } catch (err) {
      this.signInButtonState = "error";
      console.log("signInWithEmailPassword error: " + err);
      this.errorMessage = err;
    }
  }
}
