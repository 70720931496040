
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import {required, minLength, alphaNum } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().loader
  const loaders = store.getters["storage"]().Loaders.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(loaders,{tagID:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : {tagID:{required,duplicated}, registration: {required}},
  computed: {
    ...mapFields(["loader.tractor", "loader.trailer"])
  }
})
export default class Loader extends Vue {
changed: boolean = false;
tractors: Models.Tractor[] = [];
trailers: Models.Trailer[] = [];
selectedTractor?: Models.Tractor = undefined
selectedTrailer?: Models.Trailer = undefined;
cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "loader",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
 }
  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().loader);
          let loaderClone = lodash.cloneDeep(this.$store.getters["storage"]().loader);
          loaderClone.tractor = this.selectedTractor;
          loaderClone.trailer = this.selectedTrailer;
          loaderClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();//new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation loader($guid: String!, $loader: LoaderInput!) {
                loader(guid: $guid, loader: $loader)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                loader: loaderClone
              }
            },
            { root: true }
          );
         
        } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
        await this.loaders();
      }
    });
  }

  async loaders(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.loaders,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
     
      this.$store.commit("updateField", {
        path: "Loaders",
        value: json.data.Loaders
      });
    } catch (err) {
      console.log(err);
       this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/loaders" });
  }

  mounted() {
    this.tractors = lodash.cloneDeep(this.$store.getters["storage"]().Tractors) as Models.Tractor[];
    this.trailers = lodash.cloneDeep(this.$store.getters["storage"]().Trailers) as Models.Trailer[];
    this.selectedTractor = this.$store.getters["storage"]().loader.tractor;
    this.selectedTrailer = this.$store.getters["storage"]().loader.trailer;
  }

  destroyed() {}
}
