import { default as Vuex, Module, ActionContext } from "vuex";
import lodash from "lodash";
import router from "@/router";

class NotesState {
  body: string = "";
  title: string = "Gigalot Feeder";
  onEdit: { routeName?: string; listUponEditPath?: string } = {};
  onContinue?: { routeName?: string; listUponSelectionSetPath: string };
}

export default new (class Notes implements Module<NotesState, any> {
  namespaced = true;
  state: NotesState = new NotesState();
  mutations = {
    /*
    mutation(state: State, payload: any) {
      //no async calls
      state.data = payload;
    }*/
  };
  actions = {
    /*
    action(context: ActionContext<State, any>) {
      //async calls allowed, action can also be async
      //context.state, context.rootState, context.dispatch, context.commit
    }
    */

    //For the application to create its own push event, you can call this directly
    async displayNotification(context: ActionContext<NotesState, any>, payload: any) {
      console.log("Notification received");
      if (Notification.permission == "granted") {
        console.log("Notification permission granted");
        let reg = await navigator.serviceWorker.getRegistration();
        console.log(payload.body);
        if (reg) {
          var options = {
            body: payload.body,
            badge: "img/badge.png",
            icon: "img/tractor.png",
            vibrate: [50, 50, 150],
            data: payload.data
          };
          await reg.showNotification("Gigalot Feeder", options);
        }
      }
    }
  };
  getters = {
    /*
    getter(state: State, getters: any, rootState: any, rootGetters: any) {
      //return a function if you want the getter to receive input parameters
    }
    */
  
  };
})();
