
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
  }
})
export default class WhatsNew extends Vue {

}

export { WhatsNew };
