
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DimssaButton from "@/components/shared/dimssa-button.vue";
import { mapMutations } from "vuex";
import * as Models from "@gigalot/data-models";
import lodash from "lodash";
const vuexMapFields = require("vuex-map-fields");
const mapFields = vuexMapFields.mapFields;
import {required, minLength, alphaNum } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate'
import store from "@/store";

function duplicated(value:string){
  const cm = store.getters["storage"]().operator
  const operators = store.getters["storage"]().Operators.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(operators,{electronicID:value});
  return f ? false : true;
} 

function duplicated2(value:string){
  const cm = store.getters["storage"]().operator
  const operators = store.getters["storage"]().Operators.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(operators,{operatorID:value});
  return f ? false : true;
} 

function duplicated3(value:string){
  const cm = store.getters["storage"]().operator
  const operators = store.getters["storage"]().Operators.filter((m: any) => m.guid !== cm.guid);
  const f = lodash.find(operators,{operatorFullName:value});
  return f ? false : true;
} 

@Component({
  components: {
    DimssaButton
  },
  mixins: [validationMixin],
  validations : {operatorFullName:{required, duplicated3},operatorID:{required, duplicated2},
  electronicID:{required,duplicated}},
  computed: {
    ...mapFields(["operator.operatorFullName", "operator.operatorID", "operator.electronicID", "operator.authorizationLevel", "operator.active"])
  }
})
export default class Operator extends Vue {
  changed: boolean = false;

cancel() {
    if (this.changed){
      this.$store.commit("popup/displayYesNo", {
      message: "Discard changes?",
      yesAction: () => {
        this.$store.commit("updateField", {
          path: "operator",
          value: {}
        });
        this.$router.go(-1);
      }
    });
    }
    else {
      this.$router.go(-1);
    }
 }
  save() {
    this.$store.commit("popup/displayYesNo", {
      message: "Are you sure you want to save?",
      yesAction: async () => {
        try {
          console.log(this.$store.getters["storage"]().operator);
          let operatorClone = lodash.cloneDeep(this.$store.getters["storage"]().operator);
          operatorClone.metadata = this.$store.getters["user/getUpstreamMetadata"]();//new Models.UpstreamMetadata(userName, userId, userEmail, feedlot, gcp, location);
          let json = await this.$store.dispatch(
            "graphQl",
            {
              gql: `mutation operator($guid: String!, $operator: OperatorInput!) {
                operator(guid: $guid, operator: $operator)
              }`,
              variables: {
                guid: this.$store.state.user.location.guid,
                operator: operatorClone
              }
            },
            { root: true }
          );
         
         } catch (err) {
          console.log(err);
          this.$store.commit("popup/displayOk", {
            message: "Error occured while trying to save to server"
          });
          throw err;
        }
        await this.operators();
      }
    });
  }

  async operators(push: boolean = true) {
    try {
      let json = await this.$store.dispatch(
        "graphQl",
        {
          gql: Models.gql.queries.operators,
          variables: { guid: this.$store.state.user.location.guid }
        },
        { root: true }
      );
      
      this.$store.commit("updateField", {
        path: "Operators",
        value: json.data.Operators
      });
    } catch (err) {
      console.log(err);
      this.$store.commit("popup/displayOk", {
            message: `Error: ${err}`
          });
    }
    if (push) this.$router.push({ path: "list/operators" });
  }
  
  mounted() {}

  destroyed() {}
}
